import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(router)



import axios from 'axios'
import VueAxios from 'vue-axios'

const axios_instance = axios.create({
    // baseURL: 'http://localhost:3000'
    baseURL: 'https://api.greenclim.xyz'
});

app.use(VueAxios, axios_instance)
app.provide('axios', app.config.globalProperties.axios)



import  Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

app.use(
    Vue3Toasity,
    {
      autoClose: 3000,
      theme: 'dark',
      position: 'top-right'
      // ...
    },
)




app.mount('#app')
