<template>

    <div class="circle_container" @touchstart="touch($event)">
        <div class="circle">
            <div class="logo">
                <img src="../assets/greenclim.png"/>
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue"

const props = defineProps(['width'])
const width = ref('20px')

onMounted(() => {
    width.value = props.width
})

const emit = defineEmits(['tap'])

const touch = (e) => {
    console.log(e);
    

    for (let touch of e.targetTouches) {
        emit('tap', touch)
    }
    
}

</script>

<style scoped>

.circle_container {

    width: v-bind(width);
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #2DC653;
    background: linear-gradient(to top left, #07962b, #ffffff);
    /* border: 1px solid #ffffff; */
    transition: .5s;
}
.circle_container:active {
    animation: shake .5s;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.circle {
    /* width: calc(100% - 30px); */
    width: 88%;
    aspect-ratio: 1/1;
    background-color: var(--color-1);
    border-radius: 50%;


    /* border: 2px solid #00ff00; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

    display: flex;
    align-items: center;
    justify-content: center;

}
.logo {
    text-align: center;
}
.logo img{
    width: 50%;
    filter: brightness(0.25)
}
</style>